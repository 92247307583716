




















































import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue';
import { Faq, FormFaq} from '@/models';
import AlertModule from '@/store/vuex/aplicacao/AlertModule';
import { FaqModule } from '@/store/vuex/faq/FaqStore';
import { FindFaqUseCase, SaveFaqUseCase } from '@/usecases/faq';
import { nextTick } from '@/shareds/utils'
import { Vue, Component, Prop, Ref, Watch} from 'vue-property-decorator'
import { maxDigit, obrigatorio } from '@/shareds/regras-de-form';

@Component({
	components: {
		EdicaoGenerica,
	},
})


export default class TelaDeFaq extends Vue {

@Ref() edicaoGenerica!: EdicaoGenerica<Faq>
@Ref() form0!: HTMLFormElement
@Prop({ type: String, default: ''}) id!: string
@Prop({ type: Boolean }) novo!: boolean

findUseCase = new FindFaqUseCase
saveUseCase = new SaveFaqUseCase

faqFormatada: any
faq: Faq | null = null
versaoAnterior: Faq | null = null
carregando = false
salvando = false
obrigatorio = obrigatorio
maxDigit = maxDigit

erro: any | Error = null

async created() {
	this.carregar()
}

async salvar(){
	if(!this.faq) return
	try{
		this.salvando = true
		const faq: Faq = {
			...this.faq,
			titulo: this.faq.titulo,
			subTitulo: this.faq.subTitulo,
			descricao: this.faq.descricao,
		}
		const faqSalva = await this.saveUseCase.update(faq)
		this.id
			? FaqModule.atualizarFaq(faqSalva)
			: FaqModule.adicionarFaq(faqSalva)
		
		AlertModule.setSuccess('Faq salva com sucesso')
		if (this.novo) {
			this.$router.push({
				name: 'Editar Faq',
				params: { id: faqSalva.id},
			})
		}
		this.versaoAnterior = JSON.parse(JSON.stringify(this.faq))
		this.carregar
	} catch (error: any) {
		AlertModule.setError(error)
	} finally {
		this.salvando = false
	}
}

async carregar() {
	try {
		this.carregando = true
		this.faq = this.novo
			? criarFaq()
			: await this.findUseCase.findById(this.id)
		nextTick().then(() => {
			this.versaoAnterior = JSON.parse(JSON.stringify(this.faq))
		})
		FaqModule.setFaqSelecionada(this.faq)
	} catch (error: any) {
		this.erro = error
		AlertModule.setError(error)

	} finally {
		this.$nextTick(() => {
			this.carregando = false
		})

		if(this.faq) { 
			const faqFormatada = this.faq.descricao.substring(1, this.faq.descricao.length - 1)
			this.faq.descricao = faqFormatada.replace(/[\\]/g, '')
		}
		
	}
}

	@Watch('id', {immediate: true})
onChangeId() {
	this.carregar()
}

}

function criarFaq(): FormFaq {
	return {
		id: '',
		titulo: '',
		subTitulo: '',
		descricao: '',
	}
}

